import React, {Component} from 'react';
import { useParams } from 'react-router-dom';  
import axios from 'axios';
import {api_base_url, base_url} from '../common/Constants'; 

import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51QWyNq02BwVYcXbd9SqTnIVA35w2mpKzlceHklF4oBeHpMSJVd1ufjDRGq9cHo8zVVafWiZC2GvIwoXQsKwUhRt200qnzMTNj6');
 
class ConfirmPaymentContent extends React.Component{

    constructor(params){
        super(params);  
        this.state = { 
            loading : 1, 
            order:[] ,
            status:'',
            id:''
        }
    }


         
    async componentDidMount()
    {
        const order_id =  localStorage.getItem('order_id');
        if(order_id === "" ||order_id === null || order_id === undefined)
        {
           window.location.href = '/';
        }
        const stripe = await stripePromise;
        console.log(stripe);
        
        const urlParams = window.location.href.split('?')[1].split('&');
        const clientSecret = urlParams.find(param => param.startsWith('payment_intent_client_secret=')).split('=')[1];
        console.log(clientSecret);

    if (!clientSecret) {
      return;
    }
    
    const paymentIntent = await  stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
        if (!paymentIntent) {
            return;
          }
          console.log(paymentIntent);
          this.setState({status:paymentIntent.status});
          this.setState({id:paymentIntent.id});
          if(paymentIntent.status == "succeeded")
          {
            var data = { order_id : order_id, 'txn_id': paymentIntent.id, "payment_status" : "PAID"}
          }
          else{
            var data = { order_id : order_id, 'txn_id': "", "status" : "FAILED"}
          }
          axios.post(api_base_url+"update_order", data).then(response=>{
              this.setState({order:response.data}); 
              this.setState({loading:0});  
  
              localStorage.removeItem('order_session_id');
              localStorage.removeItem('order_id');
  
          });
    });
        
    }


    
    render()
    { 
        
    return (
        <div>


        <section className="about-three section-space" id="about">
            <div className="container">
                <div className="row gutter-y-60">
                    <div className="col-lg-12 wow fadeInLeft" data-wow-duration="1500ms">
                        <div className={this.state.status == "succeeded" ? "about-three__image dnone" : "about-three__image"} >

                            <p>Transaction Failed!</p>
 
                            <p><b>Status:</b> {this.state.status}</p>
                            <p><b>Txn Id:</b> {this.state.id}</p>

                        </div>
                        <div  className={this.state.status == "succeeded" ? "about-three__image " : "about-three__image dnone"} >
                           <p>Thank you for your order. One of our executives will contact you soon!</p>

                           <h3>Order Info</h3>

                           <p><b>Order No:</b> {this.state.order.order_no}</p>
                           <p><b>Order Total:</b> {this.state.order.order_total}</p>
                           <p><b>Order Status:</b> {this.state.order.order_status}</p>
                           <p><b>Payment Status:</b> {this.state.order.payment_status}</p>
                           <p><b>Status:</b> {this.state.status}</p>
                           <p><b>Txn Id:</b> {this.state.id}</p>
                            
                        </div> 
                    </div>  
                </div> 
            </div> 
            
        </section> 
 
        <br />
        <br />
        </div>
    );
}


}

export default ConfirmPaymentContent