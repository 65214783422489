import React, { Component } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix for missing marker icons in Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// LocationPicker functional component to handle clicks on the map
function LocationPicker({ onLocationSelected }) {
  useMapEvents({
    click(e) {
      onLocationSelected(e.latlng);
    },
  });
  return null;
}

class LocationMapContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [props.latitude, props.longitude], // Default center of the map
      zoom: 12, // Default zoom level
      markerPosition: [props.marker_latitude, props.marker_longitude], // Default marker position
      address: "", // Address input for geocoding
    };
     
  }

 

  handleInputChange = (event) => {
    this.setState({ address: event.target.value });
    
  };

  handleSearch = async () => {
    const { address } = this.state;

    if (!address) {
      alert("Please enter an address.");
      return;
    }

    try {
      // Fetch geocoding data from the Nominatim API
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          address
        )}`
      );
      const data = await response.json();

      if (data.length > 0) {
        const { lat, lon, display_name } = data[0];
        this.setState({
          center: [parseFloat(lat), parseFloat(lon)],
          markerPosition: [parseFloat(lat), parseFloat(lon)],
        });
        alert(`Location found: ${display_name}`);
      } else {
        alert("Address not found!");
      }
    } catch (error) {
      console.error("Error during geocoding:", error);
      alert("An error occurred while fetching location data.");
    }
  };

  handleLocationSelected = (latlng) => {
    this.setState({
      markerPosition: [latlng.lat, latlng.lng],
    });
    localStorage.setItem('marker_latitude',latlng.lat) ;
    localStorage.setItem('marker_longitude',latlng.lng) ;
  };

  render() {
    const { center, zoom, markerPosition, address } = this.state;

    return (
      <div>
        {/* Search Box */}
       {/* <div style={{ marginBottom: "10px" }}>
          <input
            type="text"
            value={address}
            onChange={this.handleInputChange}
            placeholder="Enter an address"
            style={{ padding: "8px", width: "300px", marginRight: "10px" }}
          />
          <button onClick={this.handleSearch} style={{ padding: "8px 12px" }}>
            Search
          </button>
        </div>*/}

        {/* Selected Location */}
        <div style={{ marginBottom: "10px" }}>
          <strong>Selected Location:</strong> Latitude: {markerPosition[0]}, Longitude: {markerPosition[1]}
        </div>

        {/* Map */}
        <MapContainer
          center={center}
          zoom={zoom}
          style={{ height: "500px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={markerPosition}>
            <Popup>
              Selected Location: <br />
              Latitude: {markerPosition[0]}, <br />
              Longitude: {markerPosition[1]}
            </Popup>
          </Marker>
          {/* Location Picker to handle map clicks 
          <LocationPicker onLocationSelected={this.handleLocationSelected} />*/}
        </MapContainer>
      </div>
    );
  }
}

export default LocationMapContent;
