 
import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route, useNavigate } from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../../common/Constants';
 
 
class RegisterContent extends React.Component{

    constructor(props){
        super(props);
    
        this.state = { 

            loading : 0,
            firstname:'',
            lastname:'',
            email:'',
            phone:'',
            username:'',
            password:'',
            terms:'',
            errors:{},
            fopen:false,
            message:'',
            reg_error:false,
            err_message:""
            
        }
        

    }

    componentDidMount()
    {
        
        
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        if (!this.state.firstname) {
            errors.firstname = 'Name is required';
          } 
          if (!this.state.phone ) {
            errors.phone = 'Phone is required';
          }
          if (!this.state.email || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.email)) {
          errors.email = 'Invalid email';
        }
        if (!this.state.username ) {
            errors.username = 'Username is required';
          }
          else if (this.state.username.length < 6 ) {
            errors.username = 'Username should have atleast 6 letters';
          }
          if (!this.state.password ) {
            errors.password = 'Password is required';
          }
          else if (this.state.password.length < 6) {
            errors.password = 'Password should have atleast 6 letters';
          }
          //if (!this.state.terms ) {
            //errors.terms = 'Please check this to continue';
          //}
    
        if (Object.keys(errors).length > 0) {
          this.setState({ errors });
        } else {
          
             
            var data = {  data:this.state  }
            axios.post(api_base_url+"user/new", data).then(response=>{

                if(response.data.error)
                {
                    this.setState({reg_error:true});
                    this.setState({err_message: response.data.error_message});
                    
                }
                else{
                    this.setState({reg_error:true});
                    localStorage.setItem('user_id',response.data.id) ;
                    this.setState({fopen:true});
                    this.setState({message:" Thank you for registering with us. A mail has been sent to your email address. Please follow the instructions in the mail to verify your account."});
                    setTimeout(function(){
                        this.props.navigate('/user/profile');
                    },3000);
                }
               
                //this.setState({message:" Thank you for registering with us. A mail has been sent to your email address. Please follow the instructions in the mail to verify your"});
                //localStorage.setItem('user_id',response.data.id) ;
                //window.location.href = '/confirm-order';

            }).catch((error) => { 
                  console.log('Conflict error:', error.response);
               
            });

        }
      };
    
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
      };
 
      handleFancyboxClosed = (event) => {
        this.setState({fopen:false});
      };

    handleChecked = (event) => {
        if(event.target.checked)
        {
            this.setState({terms:"checked"});
        }
        else{
            this.setState({terms:""});
        }
    };
  

    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="login-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="login-page__inner">
                            <div className="login-page__image wow fadeInLeft animated fadeLeft" data-wow-duration="1500ms" >
                                <img src="../assets/images/login-bg.png" alt="login" />
                            </div> 
                            <div className="login-page__wrap login-page__main-tab-box tabs-box wow fadeInRight animated fadeRight" data-wow-duration="1500ms" >
                                <div className="login-page__wrap__bg"  style={{backgroundImage: `url(assets/images/shapes/login-bg-1.png)`}}  ></div> 
                                <div className="login-page__wrap__top">
                                    <div className="login-page__wrap__content">
                                        <h3 className="login-page__title">welcome</h3>
                                    </div> 
                                    <ul className="tab-buttons">
                                        <li data-tab="#register" className="tab-btn boskery-btn active-btn">
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__hover"></span>
                                            <span className="boskery-btn__text">Register</span>
                                        </li> 
                                    </ul>
                                </div>
                                <div className="tabs-content">
                                     
                                <div className="tab fadeInUp animated active-tab dblock" data-wow-delay="200ms" id="register" >
                                        <span className="login-page__tab-title">sign up your alhuda account</span>
                                        <form className="login-page__form">
                                           <div className="login-page__form__input-box  ">
                                                <input type="text" placeholder="First Name" name="firstname" id="first-name"  
                                                    required="" onChange={this.handleChange} value={this.state.firstname} />
                                                {this.state.errors.firstname && <label className="error">{this.state.errors.firstname}</label>}
                                            </div> 
                                            <div className="login-page__form__input-box  ">
                                                <input type="text" placeholder="Last Name" name="lastname" id="last-name"  
                                                    required="" onChange={this.handleChange} value={this.state.lastname} />
                                                {this.state.errors.lastname && <label className="error">{this.state.errors.lastname}</label>}
                                            </div> 
                                            <div className="login-page__form__input-box">
                                                <input type="email" placeholder="Email Address"  name="email" id="email"  
                                                    required="" onChange={this.handleChange} value={this.state.email} />
                                                {this.state.errors.email && <label className="error">{this.state.errors.email}</label>}
                                                <span className="icon-email"></span>
                                            </div> 
                                            <div className="login-page__form__input-box ">
                                                <input type="text" placeholder="Phone Number" name="phone" id="phone"  
                                                    required="" onChange={this.handleChange} value={this.state.phone} />
                                                {this.state.errors.phone && <label className="error">{this.state.errors.phone}</label>}
                                                <span className="icon-phone-call"></span>
                                            </div>  
                                            <div className="login-page__form__input-box">
                                                <input type="text" placeholder="Username"  name="username" id="username"  
                                                    required="" onChange={this.handleChange} value={this.state.username} />
                                                {this.state.errors.username && <label className="error">{this.state.errors.username}</label>}
                                                <span className="icon-padlock"></span>
                                            </div> 
                                            
                                            <div className="login-page__form__input-box">
                                                <input type="password" placeholder="Password" className="login-page__password"  name="password" id="password"  
                                                    required="" onChange={this.handleChange} value={this.state.password} />
                                                {this.state.errors.password && <label className="error">{this.state.errors.password}</label>}
                                                <span className="icon-padlock"></span>
                                            </div> 
                                            <div className="login-page__form__input-box login-page__form__input-box--button">
                                                <button type="button" onClick={this.handleSubmit} className="boskery-btn login-page__form__btn">Register</button>
                                            </div> 

                                            <div className='alert alert-success '  id={this.state.fopen ? "success_cart" : "disable_cart"}>{this.state.message}</div>
                        
                                            <div className='alert alert-warning '  id={this.state.reg_error ? "success_cart" : "disable_cart"}>{this.state.err_message}</div>
                        

                                            <div className="login-page__form__input-box login-page__form__input-box--bottom">
                                                <div className="login-page__form__checked-box">
                                                    <input type="checkbox" name="terms"   id="accept-policy"   
                                                    required="" onChange={this.handleChecked} checked={this.state.terms}  />
                                                {this.state.errors.terms && <label className="error-policy">{this.state.errors.terms}</label>}
                                                    <label for="accept-policy"><span></span>I accept company privacy policy</label>
                                                </div>
                                            </div> 
                                        </form> 
                                        <div className="login-page__signin">
                                            <h4 className="login-page__signin__title">Already have an account? <Link to={base_url+"user/login"}>Sign In</Link></h4> 
                                            
                                        </div> 
                                    </div> 
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div
        style={{ display: 'none' }}
        className="fancybox-container"
        id="my-message"
      >
        <div style={{ padding: 20, textAlign: 'center' }}>
          {this.state.message}
        </div>
      </div>
        </section>
    )
    }

}

export function AppWithRouter(props){
    const navigate = useNavigate();
    return  (<RegisterContent navigate={navigate} ></RegisterContent>)
}


export default RegisterContent;