 
import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route, useNavigate } from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../../common/Constants';
 
 
class ForgotContent extends React.Component{

    constructor(props){
        super(props); 
        this.state = { 
            email:'',
            password:'', 
            errors:{},
            fopen:false,
            message:'',
            success_message:'',
            reg_error:false,
            err_message:"",
            errors:{}
        }
        

    }

    componentDidMount()
    {
        
      };

      handleSubmit = (event) =>{
        
        const errors = {};
        if (!this.state.email ) {
            errors.email = 'Email address is required';
          }
          else if (!this.state.email || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.email)) {
            errors.email = 'Invalid email';
          }  
        if (Object.keys(errors).length > 0) {
          this.setState({ errors });
        } else {
          
            this.setState({ errors });  
            var data = {  data:this.state  }
            axios.post(api_base_url+"user/forgotpassword", data).then(response=>{

                if(response.data.error)
                {
                    this.setState({reg_error:true});
                    this.setState({err_message: response.data.message});
                }
                else{
                    this.setState({reg_error:false});
                    this.setState({success_message: response.data.message});
                    this.setState({fopen: true});


                     
                
                }
               
                //this.setState({message:" Thank you for registering with us. A mail has been sent to your email address. Please follow the instructions in the mail to verify your"});
                //localStorage.setItem('user_id',response.data.id) ;
                //window.location.href = '/confirm-order';

            }).catch((error) => { 
                  console.log('Conflict error:', error.response);
               
            });

        }
      }
      handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
      };

    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="login-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="login-page__inner" style={{ maxWidth: '60%' }}>
                            <div className="login-page__image wow fadeInLeft animated fadeLeft" data-wow-duration="1500ms" >
                                <img src="../assets/images/about-1.jpg" alt="forgot password"  style={{ maxWidth: '75%' }} />
                            </div> 
                            <div className="login-page__wrap login-page__main-tab-box tabs-box wow fadeInRight animated fadeRight" data-wow-duration="1500ms" >
                                <div className="login-page__wrap__bg"  style={{backgroundImage: `url(assets/images/shapes/login-bg-1.png)`}}  ></div> 
                                <div className="login-page__wrap__top">
                                    <div className="login-page__wrap__content">
                                        <h3 className="login-page__title">Forgot Password</h3>
                                    </div> 
                                   
                                </div>
                                <div className="tabs-content">
                                    <div className="tab fadeInUp animated active-tab dblock" data-wow-delay="200ms" id="login" >
                                       
                                        <form className="login-page__form">
                                        <div className="login-page__form__input-box">
                                                <input type="text" placeholder="Email Address"  name="email" id="email"  
                                                    required="" onChange={this.handleChange} value={this.state.email} />
                                                {this.state.errors.email && <label className="error">{this.state.errors.email}</label>}
                                                <span className="icon-padlock"></span>
                                            </div> 
                                            
                                           
                                            <div className="login-page__form__input-box login-page__form__input-box--button">
                                                <button type="button" onClick={this.handleSubmit} className="boskery-btn login-page__form__btn">Get reset link</button>
                                            </div> 
                                             

                                            <div className='alert alert-success '  id={this.state.fopen ? "dblock" : "dnone"}>{this.state.success_message}</div>
                        
                        <div className='alert alert-warning '  id={this.state.reg_error ? "dblock" : "dnone"}>{this.state.err_message}</div>
    
                                        </form>  
                                    </div> 
 
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </section>
    )
    }

}


export function AppWithRouter(props){
    const navigate = useNavigate();
    return  (<ForgotContent navigate={navigate}  ></ForgotContent>)
}


export default ForgotContent;