import './App.css';

import Home from './pages/Home';
import { HashRouter, Routes, Link, Route } from 'react-router-dom'; 

import About from './pages/About';
import OurCompanies from './pages/OurCompanies';
import Shop from './pages/Shop';
import Careers from './pages/Careers';
import Gallery from './pages/Gallery';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Checkout from './pages/Checkout';
import MakePayment from './pages/MakePayment';
import Cart from './pages/Cart';
import Success from './pages/Success';
import ConfirmOrder from './pages/ConfirmOrder';
import ConfirmPayment from './pages/confirmPayment';
import UserRegister from './user/UserRegister';
import UserLogin from './user/UserLogin';
import UserProfile from './user/UserProfile';
import UserOrders from './user/UserOrders';
import UserViewOrder from './user/UserViewOrder';
import UserEditProfile from './user/UserEditProfile';
import UserChangePassword from './user/UserChangePassword';
import UserConfirm from './user/UserConfirm';
import UserForgot from './user/UserForgot';
import { SharedProvider } from './pages/createContext';
import  MapClassComponent  from './pages/location.js';
import  LocationMap  from './pages/LocationMap';

function App() {

  
  return (
    <div> 
      <SharedProvider>
      <HashRouter>
      
      <Routes>
        <Route  path="/" element={<Home />} > </Route>
        <Route  path="/about-us" element={<About />} > </Route> 
        <Route  path="/our-companies" element={<OurCompanies />} > </Route> 
        <Route  path="/shop" element={<Shop />} > </Route> 
        <Route  path="/careers" element={<Careers />} > </Route> 
        <Route  path="/gallery" element={<Gallery />} > </Route> 
        <Route  path="/blog" element={<Blog />} > </Route> 
        <Route  path="/category/:id" element={<Shop />} > </Route> 
        <Route  path="/products/:id" element={<Products />} > </Route> 
        <Route  path="/contact-us" element={<Contact />} > </Route> 
        <Route  path="/cart" element={<Cart />} > </Route> 
        <Route  path="/checkout" element={<Checkout />} > </Route> 
        <Route  path="/success" element={<Success />} > </Route> 
        <Route  path="/make-payment" element={<MakePayment />} > </Route> 
        <Route  path="/confirm" element={<ConfirmOrder />} > </Route> 
        <Route  path="/ConfirmPayment" element={<ConfirmPayment />} > </Route> 
        <Route  path="/user/register" element={<UserRegister />} > </Route> 
        <Route  path="/user/login" element={<UserLogin />} > </Route> 
        <Route  path="/user/forgot-password" element={<UserForgot />} > </Route> 
        <Route  path="/user/profile" element={<UserProfile />} > </Route> 
        <Route  path="/user/orders" element={<UserOrders />} > </Route> 
        <Route  path="/user/view-order/:id" element={<UserViewOrder />} > </Route> 
        <Route  path="/user/edit" element={<UserEditProfile />} > </Route> 
        <Route  path="/user/change-password" element={<UserChangePassword />} > </Route> 
        <Route  path="/user/confirm/:token" element={<UserConfirm />} > </Route> 
        <Route  path="/location" element={<MapClassComponent />} > </Route> 
        <Route  path="/user/location/:id" element={<LocationMap />} > </Route> 
      
    </Routes>
    </HashRouter>
    </SharedProvider>
  </div>
     
  );
}

export default App;
