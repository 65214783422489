import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom';
 import axios from 'axios'; 

import Header from '../common/Header'; 
import Footer from '../common/Footer';
import PageHeader from '../components/PageHeader'; 

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ConfirmPaymentContent from '../components/confirmPaymentContent';  

const stripePromise = loadStripe("pk_test_51QWyNq02BwVYcXbd9SqTnIVA35w2mpKzlceHklF4oBeHpMSJVd1ufjDRGq9cHo8zVVafWiZC2GvIwoXQsKwUhRt200qnzMTNj6");

 
 
class ConfirmPayment extends React.Component{

    constructor(){
        super(); 
    }
 
    render()
    {
       
        return (
            <div className="page-wrapper">
                <Header/> 
                <PageHeader title="Confirm Payment"/>
                <Elements stripe={stripePromise}>
                    <ConfirmPaymentContent stripe={stripePromise} />
                </Elements>
                <Footer/>
            </div>
       
        )
    }

}
export default ConfirmPayment;