 
import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route , useNavigate} from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../../common/Constants';
import LeftMenu, {AppWithRouter} from './LeftMenu';
import { createHashHistory } from 'history';

 
class EditProfile extends React.Component{

    constructor(props){
        super(props);
        const history = createHashHistory();
        
        this.state = { 
            user:{},
            show:false,
            firstname:'',
            lastname:'',
            email:'',
            phone:'', 
            address1:'',
            address2:'',
            city:'',
            state:'',
            country:'',
            errors:{},
            fopen:false,
            message:'',
            reg_error:false,
            err_message:""
        }
        

    }

    componentDidMount()
    {
        const user_id =  localStorage.getItem('user_id');
        
        if(user_id !== "" && user_id !== null  &&  user_id !== undefined)
        { 
            var data = { user_id : user_id}
            axios.post(api_base_url+"getuser", data).then(response=>{
                this.setState({user:response.data});  
                this.setState({show:true});  
                this.setState({firstname:response.data.firstname});  
                this.setState({lastname:response.data.lastname});  
                this.setState({email:response.data.email});  
                this.setState({phone:response.data.phone});  
                this.setState({address1:response.data.address1});  
                this.setState({address2:response.data.address2});  
                this.setState({city:response.data.city});  
                this.setState({state:response.data.state});  
                this.setState({country:response.data.country});  

                console.log(this.state);
            });
        }
        else
        {
            this.props.navigate('user/login');
        }

    }

    handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        if (!this.state.firstname) {
            errors.firstname = 'Name is required';
          } 
          if (!this.state.phone ) {
            errors.phone = 'Phone is required';
          }
          if (!this.state.email || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.email)) {
          errors.email = 'Invalid email';
        } 
        if (!this.state.address1) {
            errors.address1 = 'Addressline 1 is required';
          } 
          if (!this.state.city) {
            errors.city = 'City is required';
          } 
          if (!this.state.state) {
            errors.state = 'State is required';
          } 
          if (!this.state.country) {
            errors.country = 'Country is required';
          } 
          //if (!this.state.terms ) {
            //errors.terms = 'Please check this to continue';
          //}
    
        if (Object.keys(errors).length > 0) {
          this.setState({ errors });
        } else {
          
             
            var data = {  data:this.state, 'user_id' :this.state.user.id  }
            axios.post(api_base_url+"user/edituser", data).then(response=>{

                if(response.data.error)
                {
                    this.setState({reg_error:true});
                    this.setState({fopen:false});
                    this.setState({err_message: response.data.message});  
                }
                else{
                    
                    this.setState({fopen:true});
                    this.setState({reg_error:false});
                    this.setState({message:" Successfully updated."});
                    setTimeout(function(){
                        //window.location.href = '/user/profile';
                    },3000);
                }
               
                //this.setState({message:" Thank you for registering with us. A mail has been sent to your email address. Please follow the instructions in the mail to verify your"});
                //localStorage.setItem('user_id',response.data.id) ;
                //window.location.href = '/confirm-order';

            }).catch((error) => { 
                  console.log('Conflict error:', error.response);
               
            });

        }
      };
    
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
      };
  

    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="profile-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-12">
                         
                        <AppWithRouter selected="edit" />
                         
                    </div>
                    <div className="col-md-9 col-12">
                        <div className='user-data' id={!this.state.show ? "dnone" : "dblock"}>
                        <form className="login-page__form">
                            <div className='row'>
                                <div className="col-md-6 col-12 login-page__form__input-box  ">
                                    <input type="text" placeholder="First Name" name="firstname" id="first-name"  
                                        required="" onChange={this.handleChange} value={this.state.firstname} />
                                    {this.state.errors.firstname && <label className="error">{this.state.errors.firstname}</label>}
                                </div> 
                                <div className="col-md-6 col-12 login-page__form__input-box  ">
                                    <input type="text" placeholder="Last Name" name="lastname" id="last-name"  
                                        required="" onChange={this.handleChange} value={this.state.lastname} />
                                    {this.state.errors.lastname && <label className="error">{this.state.errors.lastname}</label>}
                                </div> 
                            </div>
                                         <div className='row'>              
                                            <div className="col-md-6 col-12  login-page__form__input-box">
                                                <input type="email" placeholder="Email Address"  name="email" id="email"  
                                                    required="" onChange={this.handleChange} value={this.state.email} />
                                                {this.state.errors.email && <label className="error">{this.state.errors.email}</label>}
                                               
                                            </div> 
                                            <div className=" col-md-6 col-12  login-page__form__input-box ">
                                                <input type="text" placeholder="Phone Number" name="phone" id="phone"  
                                                    required="" onChange={this.handleChange} value={this.state.phone} />
                                                {this.state.errors.phone && <label className="error">{this.state.errors.phone}</label>}
                                                 
                                            </div>   
                                            </div>
                                            <div className='row'>              
                                            <div className="col-md-6 col-12  login-page__form__input-box">
                                                <input type="text" placeholder="Address line1"  name="address1" id="address1"  
                                                    required="" onChange={this.handleChange} value={this.state.address1} />
                                                {this.state.errors.address1 && <label className="error">{this.state.errors.address1}</label>}
                                               
                                            </div> 
                                            <div className=" col-md-6 col-12  login-page__form__input-box ">
                                                <input type="text" placeholder="Address line2" name="address2" id="address2"  
                                                    required="" onChange={this.handleChange} value={this.state.address2} />
                                                {this.state.errors.address2 && <label className="error">{this.state.errors.address2}</label>}
                                                 
                                            </div>   
                                            </div>
                                            
                                            <div className='row'>              
                                                <div className="col-md-6 col-12  login-page__form__input-box">
                                                    <select className="selectpicker" name="country" aria-label="Default select example" onChange={this.handleChange}>
                                                        <option selected="">Select a country</option>
                                                        <option value="uae" selected={this.state.country == "uae" ? 'selected' : ''} >UAE</option> 
                                                    </select>
                                                    {this.state.errors.country && <div>{this.state.errors.country}</div>}
                                                </div> 
                                                <div className=" col-md-6 col-12  login-page__form__input-box ">
                                                         <select className="selectpicker" name='state' aria-label="Default select example" onChange={this.handleChange}>
                                                        
                                                        <option selected="">Select a State</option>
                                                                <option value="Abu Dhabi"  selected={this.state.state == "Abu Dhabi" ? 'selected' : ''}>Abu Dhabi</option>
                                                                
                
                                                        </select>
                                                        {this.state.errors.state && <div>{this.state.errors.state}</div>}
                                                </div>   
                                            </div>
                                            <div className='row'>              
                                                    <div className="col-md-6 col-12  login-page__form__input-box">
                                                        <input type="text" placeholder="City"  name="city" id="city"  
                                                            required="" onChange={this.handleChange} value={this.state.city} />
                                                        {this.state.errors.city && <label className="error">{this.state.errors.city}</label>}
                                                    
                                                    </div>  
                                                    <div className=" col-md-6 col-12 login-page__form__input-box login-page__form__input-box--button">
                                                    <button type="button" onClick={this.handleSubmit} className="boskery-btn login-page__form__btn">Update</button>
                                                </div> 
                                            </div>
                                            

                                            <div className='alert alert-success '  id={this.state.fopen ? "dblock" : "dnone"}>{this.state.message}</div>
                        
                                            <div className='alert alert-warning '  id={this.state.reg_error ? "dblock" : "dnone"}>{this.state.err_message}</div>
                        
 
                                        </form>
                                        <br/><br/>
                        </div>

                        <div className='loading '  id={this.state.show ? "dnone" : "dblock"}> <img src={base_url+"assets/images/loader.gif"} alt="Alhuda" width="150" /></div>
                    
                    </div>
                </div>
            </div>
        </section>
    )
    }

}

export function AppWithNavigate(props){
    const navigate = useNavigate();
    return  (<EditProfile navigate={navigate} ></EditProfile>)
}

export default EditProfile;