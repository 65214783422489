import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 
import ProductsSlider from './ProductsSlider';
import MD5 from 'crypto-js/md5';
import {api_base_url, base_url} from '../common/Constants';

import { SharedContext } from '../pages/createContext';

 
class ProductsContent extends React.Component{
    static contextType = SharedContext;
      
    constructor(props){
        super(props);
 
        this.state = {
            cart : [],
            loading : 1,
            qty:[] 
            
        }

    }

    componentDidMount()
    {
        const { sharedState, setSharedState } = this.context;
     
        const order_session_id =  localStorage.getItem('order_session_id');
        var data = { order_session_id : order_session_id}
        axios.post("https://alhuda.integratesolu.com/public/api/cart", data).then(response=>{
            this.setState({cart:response.data}); 
            this.setState({loading:0}); 
            localStorage.setItem('cart_count',response.data.count) ; 
            setSharedState(response.data.cart.length);
        });
        
    }
 
    removeFromCart=(e)=>{
        const { sharedState, setSharedState } = this.context;
     
        if(!window.confirm('Are you sure, you want to delete this item from cart?'))
            {
                return;
            }

        const order_session_id =  localStorage.getItem('order_session_id');
        this.setState({loading:1});  
        var data = { id : e.target.dataset.id, order_session_id : order_session_id}
        axios.post("https://alhuda.integratesolu.com/public/api/cart/remove", data).then(response=>{
            this.setState({cart:response.data}); 
            this.setState({loading:0});  
            localStorage.setItem('cart_count',response.data.count) ;
            setSharedState(response.data.cart.length);
        });
    }
 
    updateCart=(e)=>{
        const { sharedState, setSharedState } = this.context;
     
        if(!window.confirm('Are you sure, you want to delete this item from cart?'))
        {
            return;
        }

        const order_session_id =  localStorage.getItem('order_session_id');
        this.setState({loading:1});  
        var data = { id : e.target.dataset.id, order_session_id : order_session_id}
        axios.post("https://alhuda.integratesolu.com/public/api/cart/remove", data).then(response=>{
            this.setState({cart:response.data}); 
            this.setState({loading:0}); 
            localStorage.setItem('cart_count',response.data.count) ; 
            setSharedState(response.data.cart.length);
        });
    }
 
    plusHandler=(id,qty)=>{
        const { sharedState, setSharedState } = this.context;
     
        const order_session_id =  localStorage.getItem('order_session_id');
        this.setState({loading:1});  
        var data = { id : id, order_session_id : order_session_id, quantity: (qty + 1)}
        axios.post("https://alhuda.integratesolu.com/public/api/cart/update_qty", data).then(response=>{
            this.setState({cart:response.data}); 
            this.setState({loading:0}); 
            localStorage.setItem('cart_count',response.data.count) ; 
            setSharedState(response.data.cart.length);
        });
    }

    minusHandler=(id,qty)=>{
        const { sharedState, setSharedState } = this.context;
     
        const order_session_id =  localStorage.getItem('order_session_id');
        this.setState({loading:1});  
        if(qty === 1)
        {
            if(!window.confirm('Are you sure, you want to delete this item from cart?'))
                {
                    return;
                }
            const order_session_id =  localStorage.getItem('order_session_id');
            this.setState({loading:1});  
            var data = { id : id, order_session_id : order_session_id}
            axios.post("https://alhuda.integratesolu.com/public/api/cart/remove", data).then(response=>{
                this.setState({cart:response.data}); 
                this.setState({loading:0}); 
                localStorage.setItem('cart_count',response.data.count) ; 
                setSharedState(response.data.cart.length);
            });
        }
        else
        {
        var data = { id : id, order_session_id : order_session_id,  quantity: (qty - 1)}
        axios.post("https://alhuda.integratesolu.com/public/api/cart/update_qty", data).then(response=>{
            this.setState({cart:response.data}); 
            this.setState({loading:0});  
            localStorage.setItem('cart_count',response.data.count) ;
            setSharedState(response.data.cart.length);
        });
        }
    }


    render()
    { 
        
        if (this.state.loading == 1) {
            return  (
<section className="cart-page section-space">
            <div className="container">
                <div className="row gutter-y-50">
                    <div className="col-xl-8">
                        <div className="table-responsive">
                            <table className="table cart-page__table">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Sub Total</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                                </table>

                                <div className='loading '  > <img src={base_url+"assets/images/loader.gif"} alt="Alhuda" width="150" /></div>
                    
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>

            );



          }

         
    return (

            <div>
                
        <section className="cart-page section-space">
            <div className="container">
                <div className="row gutter-y-50">
                    <div className="col-xl-8">
                        <div className="table-responsive">
                            <table className="table cart-page__table">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Sub Total</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>

                                {this.state.cart.cart.map((item, index) => (
                                            <tr >
                                            <td>
                                                <div className="cart-page__table__meta">
                                                    <div className="cart-page__table__meta__img">
                                                        <img src={item.image} alt={item.title} />
                                                    </div>
                                                    <h3 className="cart-page__table__meta__title"><Link to={`/products/${item.product_slug}`}>{item.title}</Link>
                                                    <p> Size:{item.size}</p>
                                                    </h3>
                                                  
                                                   
                                                </div>
                                            </td>
                                            <td className="cart-page__table__price">{item.price}</td>
                                            <td>
                                                <div className="product-details__quantity">
                                                    <div className="quantity-box">
                                                        <button type="button" className="sub" onClick={() => this.minusHandler(item.id,item.quantity)} ><i className="fa fa-minus"></i></button>
                                                        <input type="text" readonly value={item.quantity} />
                                                        <button type="button" className="add"  onClick={() => this.plusHandler(item.id,item.quantity)} ><i className="fa fa-plus"></i></button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="cart-page__table__total">{item.total_price}</td>
                                            <td>
                                                <Link className="cart-page__table__remove" data-id={item.id} onClick={this.removeFromCart}><i className="fas fa-times"></i>
                                                    remove</Link>
                                            </td>
                                        </tr>
                                ))}

                                <tr  id={this.state.cart.cart.length == 0 ? "dblock" :"dnone"}>
                                    <td colspan="4" className='colSpan4' >Your cart is empty</td>
                                </tr>

                                   
                                </tbody>
                            </table>
                        </div>
                         
                    </div> 
                    <div className="col-xl-4">
                        <div className="cart-page__cart-checkout" id={this.state.cart.cart.length > 0 ? "dblock" :"dnone"}>
                            <ul className="cart-page__cart-total list-unstyled">
                                <li><span>Cart Totals</span>
                                </li>
                                <li><span>Organ Meats</span>
                                </li>
                                <li><span>Sub Total</span><span className="cart-page__cart-total__amount">{this.state.cart.subtotal}</span></li>
								<li><span>VAT 5%</span><span className="cart-page__cart-total__amount">{this.state.cart.vat}</span></li> 
								<li><span>Delivery charges</span><span className="cart-page__cart-total__amount">{this.state.cart.delivery_charges}</span></li> 
								<li><span>Total</span><span className="cart-page__cart-total__amount">{this.state.cart.grand_total}</span></li> 
                            </ul>
                            <Link to="/checkout" className="boskery-btn cart-page__checkout-btn">
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__text">checkout</span>
                            </Link>
                        </div>
                    </div> 
                </div> 
            </div> 
        </section> 
<br /><br />
            </div>
        
    );
}

}

export default ProductsContent;