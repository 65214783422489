 
import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route, useNavigate } from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../../common/Constants';
 
 
class ConfirmContent extends React.Component{

    constructor(props){
        super(props); 
        this.state = { 
            loading:0,
            error:false,
            error_message : "",
            message:""
        }
        

    }

    componentDidMount()
    { 
        var data = {  token : this.props.token }
        axios.post(api_base_url+"user/confirm", data).then(response=>{
            
                this.setState({loading:1});
                if(response.data.error)
                {
                    this.setState({error:true});
                    this.setState({error_message:response.data.error_message});
                }
                else{
                    this.setState({error:false});
                    this.setState({error_message:""}); 
                    this.setState({message:response.data.message});
                }
             
        });
        
    };
 

    render()
    { 
        if (this.state.loading == 0) {
            return <div>Loading...</div>;
          }
    return (
        <section className="login-page">
            <div className="container">
                <div className="row">
                    <div  className={!this.state.error ? "dblock col-md-12" : "dnone col-md-12"} >
                        <p>Your email address has been successfully verified. You can now login using your credentials.</p>

                        <Link to={base_url+"user/login"}> <button type="button"  className="boskery-btn login-page__form__btn confirm-login-btn"  >Login</button></Link>
                    </div>
                    <div  className={this.state.error ? "dblock col-md-12" : "dnone col-md-12"}>
                       <div className="alert alert-warning">  </div> <p>{this.state.error_message}</p>
                    </div>
                </div>
            </div>
        </section>
    )
    }

}


export function AppWithRouter(props){
    const navigate = useNavigate();
    return  (<ConfirmContent navigate={navigate} token={props.token}  ></ConfirmContent>)
}


export default ConfirmContent;