import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route, useNavigate } from 'react-router-dom'; 
 
import axios from 'axios';
import {api_base_url, base_url} from '../common/Constants'; 
 
class ConfirmOrderContent extends React.Component{

    constructor(){
        super();  
        this.state = {
            content : [],
            loading : 1,
            cart:[],
            order:[],
            payment_method:'COD'
        }
    }

    handleSubmit = (event) => {

        event.preventDefault();
        const order_id =  localStorage.getItem('order_id');
        var data = { order_id : order_id, payment_method:this.state.payment_method   }
        axios.post(api_base_url+"confirm_order", data).then(response=>{

            if(this.state.payment_method == "COD")
            {
                this.props.navigate('/success');
            }
           else{
            this.props.navigate('/make-payment');
           }
        });
        

    };

    handlePaymentTypeStripe = (event) => {

        this.setState({payment_method:'Stripe'});
    
    };
    

    handlePaymentTypeCOD = (event) => {

        this.setState({payment_method:'COD'});
    
    };
    
        
    componentDidMount()
    {
        const order_id =  localStorage.getItem('order_id');
        var data = { order_id : order_id}
        axios.post(api_base_url+"get_order", data).then(response=>{
            this.setState({order:response.data}); 
            this.setState({loading:0});  
        });
    }
    


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <div>
        <section className="checkout-page section-space">
            <div className="container">
                <div className="row gutter-y-30">
                    <div className="col-xl-8 col-lg-7">
                        <div className="checkout-page__billing-address">
                            <h2 className="checkout-page__billing-address__title checkout-page__title">Billing Details</h2>
                            <form className="checkout-page__form row gutter-y-16">
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="full-name">Billing Name: </label>
                                        {this.state.order.billing_firstname} {this.state.order.billing_lastname}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="email">Billing Email Address: </label>
                                        {this.state.order.billing_email}
          
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="company-name">Billing Address: </label>
                                        {this.state.order.billing_address}
                                    </div>
                                </div> 
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="phone">Billing Phone: </label>
                                        {this.state.order.billing_phone}</div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="full-name">Shipping Name: </label>
                                       {this.state.order.billing_name}
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="email">Shipping Email Address: </label>
                                        {this.state.order.billing_email}
          
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="company-name">Shipping Address: </label>
                                        {this.state.order.billing_address}
                                    </div>
                                </div> 
                                <div className="col-xl-12">
                                    <div className="checkout-page__input-box">
                                        <label for="phone">Shipping Phone: </label>
                                        {this.state.order.billing_phone}</div>
                                </div>
                                 
                            </form>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                        <div className="checkout-page__your-order">
                            <h2 className="checkout-page__your-order__title checkout-page__title">Your order</h2>
                            <table className="checkout-page__order-table">
                                
                                <tbody>
                                
                                    <tr>
                                        <td className="pro__sub-title">Subtotal</td>
                                        <td className="pro__sub-price">{this.state.order.subtotal}</td>
                                    </tr>
									<tr>
                                        <td className="pro__sub-title">VAT 5%</td>
                                        <td className="pro__sub-price">{this.state.order.vat_price}</td>
                                    </tr>
									<tr>
                                        <td className="pro__sub-title">Delivery Charges</td>
                                        <td className="pro__sub-price">{this.state.order.shipping_charge}</td>
                                    </tr>
									<tr>
                                        <td className="pro__sub-title">Total</td>
                                        <td className="pro__sub-price">{this.state.order.order_total}</td>
                                    </tr>
                                
                                    <tr>
                                        <td colspan="2">
                                            <div className="checkout-page__radio-box checkout-page__radio-box--right"> 
                                                <div className="checkout-page__input-item custom-radio">
                                                    <input  onClick={this.handlePaymentTypeStripe} type="radio"  checked={this.state.payment_method == "Stripe" ? true : false} id="check"  name="order-info" className="custom-radio__input" />
                                                    <label for="check" className="custom-radio__title">Online Payments</label>
                                                </div>
                                                <div className="checkout-page__input-item custom-radio">
                                                    <input  onClick={this.handlePaymentTypeCOD} type="radio" id="cash"  checked={this.state.payment_method == "COD" ? true : false} name="order-info" className="custom-radio__input" />
                                                    <label for="cash" className="custom-radio__title">Cash On Delivery</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p className="checkout-page__order-text">Your personal data will be used to process your
                                                order, support your experience throughout this website.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <Link onClick={this.handleSubmit} className="checkout-page__order-btn boskery-btn">
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__hover"></span>
                                                <span className="boskery-btn__text">Place Order</span>
                                                <i className="icon-meat-3"></i>
                                            </Link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section> 

<br /><br />
            </div>
    );
}

}

export function AppWithRouter(props){
    const navigate = useNavigate();
    return  (<ConfirmOrderContent navigate={navigate} ></ConfirmOrderContent>)
}


export default ConfirmOrderContent;