import React  from 'react';
import { Link } from 'react-router-dom'; 
 
import axios from 'axios'; 
import ProductsSlider from './ProductsSlider';
import MD5 from 'crypto-js/md5';
import { SharedContext } from '../pages/createContext';
import {api_base_url} from '../common/Constants'; 

class ProductsContent extends React.Component{
    static contextType = SharedContext;
   
    constructor(props){
        super(props); 

        this.state = {
            product : [],
            loading : 1,
            qty:1,
            size:1,
            sizes:[],
            size_list:[],
            success_cart : 0,
            price:0,
            org_price:0,
            remarks:'',
            discount:0 
        }
        

    }

    componentDidMount()
    {
        const { sharedState, setSharedState } = this.context;
        var data = { slug : this.props.slug}
        axios.post(api_base_url+"products", data).then(response=>{
            this.setState({product:response.data}); 
            this.setState({price:response.data.price}); 

            var datas = { product_id: response.data.id}
        

            axios.post(api_base_url+"sizes", datas).then(response=>{
                this.setState({sizes:response.data.sizes}); 
                this.setState({size_list:response.data.sizes_list}); 
                this.setState({size:response.data.sizes[0].id}); 
                this.setState({loading:0});  
                this.setState({price:response.data.sizes[0].old_price});
                this.setState({old_price:response.data.sizes[0].price});
                this.setState({org_price:response.data.sizes[0].org_price});
                this.setState({discount:response.data.sizes[0].discount});
 
            });
           
        });

        
        const order_session_id =  localStorage.getItem('order_session_id');
        var datacart = { order_session_id : order_session_id}
        axios.post(api_base_url+"cart", datacart).then(response=>{ 
            this.setState({cartcount:response.data.cart.length});  
            setSharedState(response.data.cart.length);
        });
        
    }

    addToCart=()=>{
        const { sharedState, setSharedState } = this.context;
        var order_session_id =  localStorage.getItem('order_session_id');
        if(order_session_id === "" || order_session_id === null || order_session_id === undefined)
        {
            const currentTime = new Date().getTime();
            order_session_id = MD5(currentTime.toString()).toString();
            localStorage.setItem('order_session_id',order_session_id) ;
        }

        var data = { qty : this.state.qty, price : this.state.org_price, product_id : this.state.product.id, order_session_id : order_session_id, size:this.state.size, remarks:this.state.remarks}
        axios.post(api_base_url+"cart/add", data).then(response=>{
            this.setState({success_cart:1});
            setSharedState(response.data.cart.length);
             
        });
    }


    sizeHandler=(e)=>{

        this.setState((prev,props)=>
            (
                {size:e.target.value}
        ));

        console.log(this.state.size_list[e.target.value]);
        this.setState( {price:this.state.size_list[e.target.value].old_price});
        this.setState( {old_price:this.state.size_list[e.target.value].price});
        this.setState( {org_price:this.state.size_list[e.target.value].org_price});
        this.setState( {discount:this.state.size_list[e.target.value].discount});
    
    }

    remarksHandler=(e)=>{


        this.setState((prev,props)=>
            (
                {remarks:e.target.value}
        ));
 
    }
    plusHandler=()=>{


        this.setState((prev,props)=>
            (
                {qty:prev.qty + 1}
        ));
    }

    minusHandler=()=>{
        
        if(this.state.qty > 0)
            this.setState((prev,props)=>
                (
                    {qty:prev.qty - 1}
            ));
    }


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }

         
    return (

       
        
        <div>
 
         <section className="product-details section-space">
            <div className="container">
                 
                <div className="row gutter-y-50">
                    <div className="col-lg-6 col-xl-6 wow fadeInLeft" data-wow-delay="200ms">
                        <ProductsSlider image={this.state.product.image} title={this.state.product.title}></ProductsSlider>
                        
                    </div> 
                    <div className="col-lg-6 col-xl-6 wow fadeInRight" data-wow-delay="300ms">
                        <div className="product-details__content">
                            <div className="product-details__top">
                                <div className="product-details__top__left">
                                    <h3 className="product-details__name">{this.state.product.title}</h3> 
                                    
                                    <h4 className="product-details__price"><span  id={this.state.discount > 0 ? "view_old_price" : "hide_old_price"}>{this.state.price}</span> 
                                    {this.state.old_price}</h4> 
                                </div> 
                                
                            </div>
                            
                            <div className="product-details__excerpt">
                                <p className="product-details__excerpt__text">
                                   {this.state.product.short_info}
                                </p>
                            </div> 
                            <br />
                            <div className="product-details__size">
                                <h3 className="product-details__content__title">Size</h3>
                                <div className="product-details__size__box">
                                    <select id="cars" name="cars" onChange={this.sizeHandler}>

                                    {this.state.sizes.map((item, index) => (
                                        <option value={item.id}  >{item.size}</option>
                                    ))}
                                        
                                        
                                        </select>
                                </div> 
                            </div> 
                            <div className="product-details__info">
                                <div className="product-details__quantity">
                                    <h3 className="product-details__content__title">Quantity</h3>
                                    <div className="quantity-box">
                                        <button type="button" className="sub" onClick={this.minusHandler}><i className="fa fa-minus"></i></button>
                                        <input type="text" id="1" value={this.state.qty} />
                                        <button type="button" className="add" onClick={this.plusHandler}><i className="fa fa-plus"></i></button>
                                    </div>
                                </div> 
                            </div> 
                            <div className="product-details__info"> 
                            <div className="product-details__quantity">
                                    <h3 className="product-details__content__title">Remarks</h3>
                                    <div className="remarks-box"> 
                                        <textarea id="remarks"  onChange={this.remarksHandler} value={this.state.remarks} ></textarea>
                                    </div>
                                </div> 
                                </div>
                            <div className="product-details__buttons">
                                <Link   className="product-details__btn boskery-btn" onClick={this.addToCart}>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__text">Add To Cart</span>
                                    <i className="icon-meat-3"></i>
                                </Link>
                            </div> 
                            <div className='alert alert-success '  id={this.state.success_cart == 1 ? "success_cart" : "disable_cart"}>Successfully added to cart!</div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="product-details__description-wrapper">
                <div className="container">
                   
                    <div className="product-details__description">
                        <h3 className="product-details__description__title">product Description</h3>
                        <div className="product-details__text__box wow fadeInUp" data-wow-delay="300ms">
                        {this.state.product.description}
                        </div>
                    </div>
                  
                </div>
            </div>

            
        </section> 
        </div>
    );
}

}

export default ProductsContent;